<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">PRESENTACIONES DE UN PRODUCTO</h1>
    <div class="row p-3" v-if="producto.nombre">
      <div class="col-12" style="font-size: 35px">
        {{ producto.nombre | todasLasPrimerasMayusculas }}
      </div>
      <div class="col-12">
        {{ producto.descripcion }}
      </div>
    </div>
    <div style="padding: 30px">
      <b-form ref="formAdd" style="border: 1px solid #d8d8d8">
        <div class="row p-3">
          <!-- selector de presentacion -->
          <b-form-group
            id="presentacionId"
            label="Presentacion*"
            label-for="presentacionId"
            class="col-md-3"
          >
            <b-form-select
              id="presentacionId"
              v-model="form.presentacion_id"
              :options="presentaciones"
              ref="presentacionId"
              :state="presentacionIdState"
              required
            ></b-form-select>
          </b-form-group>
          <!-- PRECIO -->
          <b-form-group
            id="precio"
            label="Precio*"
            label-for="precio"
            class="col-md-3"
          >
            <b-form-input
              id="precio"
              v-model.trim="form.precio"
              type="number"
              placeholder="precio del producto"
              ref="precio"
              :state="precioState"
              required
              step="0.1"
              :min="0.1"
            ></b-form-input>
          </b-form-group>
          <!-- disponible -->
          <b-form-group label="Disponible" class="col-md-3">
            <b-form-radio-group
              v-model="form.disponible"
              :options="optionsDisponible"
              class="mb-3"
              value-field="item"
              text-field="name"
            ></b-form-radio-group>
          </b-form-group>
          <div
            class="col-md-3 d-flex align-items-center justify-content-center"
          >
            <b-button variant="primary" @click="handleSubmit">{{
              type == "edit" ? "Guardar Cambios" : "Agregar"
            }}</b-button>
            <b-button variant="danger" class="ml-3" @click="reset">{{
              type == "edit" ? "Cancelar" : "Salir"
            }}</b-button>
          </div>
        </div>
      </b-form>
    </div>

    <div class="container-actions-top-table">
      <div class="container-searcher">
        <b-input-group size="sm" class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            type="search"
            placeholder="Buscador"
            v-model.trim="textSearch"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <!-- tabla de presentaciones agregadas al producto -->
    <div class="container-table">
      <b-table
        striped
        :stacked="windowWidth < 600"
        hover
        :items="items"
        :fields="fields"
        :busy="loadingData"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="textSearch.toLowerCase()"
      >
        <template #cell(disponible)="data">
          {{ data.item.disponible == 1 ? "SI" : "NO" }}
        </template>
        <template #cell(imagen)="data">
          <img
            :src="`${urlImagesCategorias}${data.item.imagen}`"
            alt=""
            height="100"
          />
        </template>
        <template #cell(actions)="data">
          <b-icon
            icon="pencil-square"
            variant="warning"
            @click="setModoEdition({ ...data.item })"
            scale="1.5"
            style="margin: 0px 10px; cursor: pointer"
            v-b-tooltip.hover
            title="Editar"
          ></b-icon>
          <b-icon
            icon="x-circle"
            variant="danger"
            @click="deleteItem({ ...data.item })"
            scale="1.5"
            style="margin: 0px 10px; cursor: pointer"
            v-b-tooltip.hover
            title="Eliminar"
          ></b-icon>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style="margin-left: 5px">Cargando Datos...</strong>
          </div>
        </template>
      </b-table>
    </div>
    <div class="container-paginator">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <span style="margin-left: 15px">Items por página</span>
      <b-form-select
        v-model="perPage"
        :options="optionsPerPage"
        style="width: 80px; margin-left: 10px"
      ></b-form-select>
    </div>
  </div>
</template>

<script>
import ProductosPresentacionesService from "@/services/productos-presentaciones.service";
import ProductosService from "@/services/productos.service";
import PresentacionesService from "@/services/presentaciones.service";
import EventBus from "@/services/eventBus.js";
export default {
  name: "UserProcessAdmin",
  data() {
    return {
      productosService: null,
      presentacionesService: null,
      productosPresentacionesService: null,
      producto: {},
      form: {
        producto_id: null,
        presentacion_id: null,
        precio: null,
        disponible: 2,
      },
      precioState: null,
      presentacionIdState: null,
      optionsDisponible: [
        { item: 1, name: "SI" },
        { item: 2, name: "NO" },
      ],
      presentaciones: [{ text: "Seleccione una presentación", value: null }],
      type: "create",
      sortBy: "presentacion",
      fields: [
        { key: "presentacion", sortable: true },
        { key: "precio", sortable: true },
        { key: "disponible", sortable: true },
        { key: "actions", sortable: false },
      ],
      from: "productopresentacionesadmin",
      items: [],
      windowWidth: window.innerWidth,
      loadingData: false,
      perPage: 10,
      currentPage: 1,
      optionsPerPage: [5, 10, 25, 50, 100],
      textSearch: "",
      itemSeleted: null,
      sortDesc: false,
    };
  },
  created() {
    this.productosService = new ProductosService();
    this.presentacionesService = new PresentacionesService();
    this.productosPresentacionesService = new ProductosPresentacionesService();
    this.$route.params.id
      ? (this.form.producto_id = this.$route.params.id)
      : this.$router.push({ name: "Admin" });
  },
  mounted() {
    this.recoveryDataProducto();
    this.recuperarPresentaciones();
    this.recoveryPresentacionesDelProducto(this.$route.params.id);
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
    EventBus.$on("RESPONSE_MODAL_QUESTION", (payLoad) => {
      if (payLoad.from == this.from) {
        switch (payLoad.action) {
          case "delete":
            this.ejecuteDelete();
            break;
          default:
            break;
        }
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    recoveryDataProducto() {
      this.productosService
        .getById(this.$route.params.id)
        .then((result) => {
          this.producto = {};
          if (result.data.data) {
            this.producto = { ...result.data.data };
          } else {
            this.loadingData = false;
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: "El producto no existe",
            });
            this.$router.push({ name: "ProductosList" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    recuperarPresentaciones() {
      this.presentacionesService
        .getAll()
        .then((result) => {
          this.presentaciones = this.presentaciones.concat(
            result.data.data.map((item) => {
              return { text: item.nombre, value: item.id };
            })
          );
        })
        .catch(() => {
          this.loadingData = false;
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al recuperar listado de presentaciones",
          });
        });
    },
    recoveryPresentacionesDelProducto(id) {
      this.loadingData = true;
      this.productosPresentacionesService
        .getByProductId(id)
        .then((result) => {
          this.items = [...result.data.data];
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al recuperar items de la lista",
          });
        });
    },
    handleSubmit() {
      if (!this.validation()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debes completar los campos obligatorios",
        });
        return false
      }
      if (this.type === "create") {
        this.createRegister();
      } else {
        this.editRegister();
      }
    },
    createRegister() {
      this.inProcess = true;
      this.productosPresentacionesService
        .createProductoPresentacion(this.form)
        .then((result) => {
          if (result.data.status == 200) {
            this.form = {
              producto_id: this.producto.id,
              presentacion_id: null,
              precio: null,
              disponible: 2,
            };
            this.type = "create";
            this.items.push(result.data.data);
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro creado satisfactoriamente",
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: result.data.errores.join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al guardar el registro",
          });
          this.inProcess = false;
        });
    },
    editRegister() {
      this.inProcess = true;
      this.productosPresentacionesService
        .editProductoPresentacion(this.form)
        .then((result) => {
          if (result.data.status == 200) {
            this.items = this.items.filter((item) => {
              return item.id != this.itemSeleted.id;
            });
            this.items.push(result.data.data);
            this.reset();
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro editado satisfactoriamente",
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: result.data.errores.join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al editar el registro",
          });
          this.inProcess = false;
        });
    },
    validation() {
      /* validando precio*/
      const validPrecio = this.$refs.precio.checkValidity();
      this.precioState = validPrecio;
      /* validando un select*/
      const validacionPresentacionIdSelect = this.$refs.presentacionId.required
        ? this.$refs.presentacionId.value
          ? true
          : false
        : true;
      this.presentacionIdState = validacionPresentacionIdSelect;
      return validPrecio && validacionPresentacionIdSelect;
    },
    deleteItem(item) {
      this.itemSeleted = { ...item };
      EventBus.$emit("SHOW_MODAL_QUESTION", {
        titleModal: this.$t('messages.elimination'),
        messageModal: "¿Seguro desea eliminar el registro?",
        action: "delete",
        from: this.from,
      });
    },
    ejecuteDelete() {
      if (!this.itemSeleted) return false;
      this.productosPresentacionesService
        .deletePresentacionDeProducto(this.itemSeleted.id)
        .then((result) => {
          if (result.data.status == 200) {
            this.items = this.items.filter((item) => {
              return item.id != this.itemSeleted.id;
            });
            this.itemSeleted = null;
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro eliminado satisfactoriamente",
            });
          }
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al eliminar registro",
          });
        });
    },
    setModoEdition(item) {
      this.itemSeleted = { ...item };
      this.form = {
        id: item.id,
        producto_id: item.producto_id,
        presentacion_id: item.presentacion_id,
        precio: item.precio,
        disponible: item.disponible,
      };
      this.type = "edit";
    },
    reset() {
      if (this.type == "create") this.$router.push({ name: "ProductosList" });
      this.itemSeleted = null;
      this.form = {
        producto_id: this.$route.params.id,
        presentacion_id: null,
        precio: null,
        disponible: 2,
      };
      this.type = "create";
    },
  },
  watch: {
    $route(to) {
      this.type = "create";
      to.params.id
        ? (this.form.producto_id = to.params.id)
        : this.$router.push({ name: "Admin" });
      this.recoveryDataProducto();
      this.recoveryPresentacionesDelProducto(this.$route.params.id);
      this.reset();
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
}
.container-table {
  width: 100%;
  min-height: 300px;
  padding-bottom: 20px;
}
.container-paginator {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  padding-bottom: 20px;
}
.container-actions-top-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
}
@media (max-width: 470px) {
  .container-paginator {
    flex-direction: column;
    align-items: center;
  }
}
</style>
