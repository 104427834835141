import axios from "axios";

export default class ProductosPresentacionesService {
  getByProductId(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "produpres/producto/"+id)
  }
  deletePresentacionDeProducto(id){
    const data={
      id
    }
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "prodpres",{data})
  }
  createProductoPresentacion(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "produpres",data)
  }

  editProductoPresentacion(data){
    return axios.put(process.env.VUE_APP_API_PUBLIC + "prodpres/edit",data)
  } 
}
